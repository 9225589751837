import Vue from 'vue'
import Router from "vue-router";

Vue.use(Router)

const routes = [
  {
    path: '/',
    redirect: to => {
      const authHeaders = $cookies.get('adminAuth')

      if (authHeaders) return { name: 'dashboard' }

      return { name: 'auth-login' }
    },
  },
  {
    path: '/dashboard',
    component: () => import('@/components/body'),
    children: [
      {
        path: '',
        name: 'dashboard',
        component: () => import('@/views/Dashboard.vue'),
      },
      {
        path: '/customers',
        name: 'customer',
        component: () => import('@/views/customer/Customer.vue'),
      },
      {
        path: '/customers/member/:id',
        name: 'member-preview',
        component: () => import('@/views/customer/MemberPreview.vue'),
      },
      {
        path: '/items',
        name: 'item',
        component: () => import('@/views/item/Item.vue'),
      },
      {
        path: '/orders',
        name: 'order',
        component: () => import('@/views/order/Order.vue'),
      },
      {
        path: '/receipt/form/:id?',
        name: 'receipt-form',
        component: () => import('@/views/receipt/ReceiptForm.vue'),
      },
      {
        path: '/receipt/:id',
        name: 'receipt-view',
        component: () => import('@/views/receipt/ReceiptView.vue'),
      },
      {
        path: '/vouchers',
        name: 'voucher',
        component: () => import('@/views/voucher/Voucher.vue'),
      }
    ]
  },
  {
    path: '/member/:preview_token',
    name: 'membership-card-preview',
    component: () => import('@/views/preview/MemberPreview.vue'),
  },
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/Login.vue'),
  }];

const router = new Router({
  routes,
  base: '/',
  mode: 'history',
  linkActiveClass: "active",
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
});

router.beforeEach((to, from, next) => {
  const authHeaders = $cookies.get('adminAuth')
  if (to.path == "/login") {
    if (authHeaders) {
      return next('/')
    } else {
      return next()
    }
  }
  if (to.name == "membership-card-preview") return next()
  if (!authHeaders) return next({ name: 'auth-login' })

  return next()
})

export default router
