import Vue from "vue";
import Vuex from "vuex";
// import 'es6-promise/auto';
import layout from './modules/layout'
import menu from './modules/menu'

import customerStoreModule from './customer/customerStoreModule'
import memberStoreModule from './customer/memberStoreModule'
import itemStoreModule from './item/itemStoreModule'
import orderStoreModule from './order/orderStoreModule'
import receiptStoreModule from './receipt/receiptStoreModule'
import voucherStoreModule from './receipt/voucherStoreModule'
import dashboardStoreModule from './dashboard/dashboardStoreModule'
import previewStoreModule from './preview/previewStoreModule'

Vue.use(Vuex);

export const store = new Vuex.Store({
    state: {
      snackbar: {
        content: '',
        status: '',
        errors: [],
      },
    },
    getters: {
      isSnackbarVisible(state) {
        return state.snackbar.isSnackbarVisible
      },
    },
    mutations: {
      SHOW_SNACKBAR(state, value) {
        state.snackbar.content = value.content
        state.snackbar.status = value.status
        state.snackbar.errors = value.errors
      },
    },
    actions: {
      showSnackbar({ commit }, value) {
        commit('SHOW_SNACKBAR', value)
      },
    },
    modules: {
      layout,
      menu,
      customerStore: customerStoreModule,
      itemStore: itemStoreModule,
      orderStore: orderStoreModule,
      receiptStore: receiptStoreModule,
      voucherStore: voucherStoreModule,
      dashboardStore: dashboardStoreModule,
      memberStore: memberStoreModule,
      previewStore: previewStoreModule,
    }
});

