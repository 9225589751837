import { axios, baseURL } from '@/plugins/axios.js'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchReceipt(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/receipts/${params.id}`, { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    createReceipt(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('api/v1/receipts', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateReceipt(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .put(`api/v1/receipts/${params.id}`, params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteReceipt(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/v1/receipts/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  }
}