import Vue from 'vue'
import App from './App.vue'
import BootstrapVue from 'bootstrap-vue'
import router from './router'
import { store } from './store';
import VueFeather from 'vue-feather';
import VueCookies from 'vue-cookies'

// Import Theme scss
import './assets/scss/app.scss'

Vue.use(VueFeather);
Vue.use(BootstrapVue)
Vue.use(VueCookies, { expires: '7d'})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')