import axios from 'axios'
const baseURL = process.env.VUE_APP_NIAGAMAS_API_URL

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchVouchers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${baseURL}/api/v1/vouchers`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    checkVoucher(ctx, { code }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${baseURL}/api/v1/vouchers/${code}/check`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchVoucher(ctx, { code }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${baseURL}/api/v1/vouchers/${code}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateVoucher(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .put(`${baseURL}/api/v1/vouchers/${params.code}`, params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}