<template>
  <div id="app">
    <Snackbar />
    <!--<Main/>-->
    <router-view></router-view>
  </div>
</template>

<script>
import Snackbar from '@/components/snackbar.vue'
export default {
  name: 'app',
  components: {
    Snackbar,
  },
  data(){
    return{
      show: true
    }
  },
  mounted() {
      this.timeOut();
    },
    methods:{
      timeOut(){
            var self = this;
            setTimeout(function(){
                self.show = false;
            }, 1000);
      }
    }
  }
</script>

<style>
.page-wrapper {
  background-color: #f8f9fa !important;
}
.page-body {
  min-height: calc(100vh - 150px) !important;
}
th div {
  padding: 5px 0;
}
.table-action .feather {
  cursor: pointer;
}
.table-light-red td:first-child {
  padding-top: 10px !important;
  padding-bottom: 0 !important;
}
.datepicker-here {
  background-color: white !important;
}
.vdp-datepicker__calendar .cell.selected {
  background-color: var(--theme-deafult) !important;
  border-color: var(--theme-deafult) !important;
  color: white;
}
.vdp-datepicker__calendar .cell:hover {
  border-color: var(--theme-deafult) !important;
}
@media (max-width: 991px) {
  .page-body {
    min-height: calc(100vh - 135px) !important;
  }
}
@media (max-width: 767.98px) {
  .new-btn,
  .show-delete-btn,
  .print-customer-btn {
    width: 100%;
  }
}
@media (max-width: 575px) {
  th, tr:not(.b-table-details) td:not(:last-child) {
    padding: 10px 0px 10px 20px !important;
  }
  th:first-child {
    width: 80px;
  }
}
.table-light-red {
  background-color: #FFEBEE !important;
}
</style>

<style lang="scss" scoped>
.loader-wrapper.loderhide {
  display: none;
}
</style>
