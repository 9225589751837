import { axios, baseURL } from '@/plugins/axios.js'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchStats(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/dashboard', { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  }
}