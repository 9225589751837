import { axios, baseURL } from '@/plugins/axios.js'
import { DirectUpload } from 'activestorage'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchMembership(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/memberships/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    createMembership(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('api/v1/memberships', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateMembership(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .put(`api/v1/memberships/${params.id}`, params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    directUploadIc(ctx, file) {
      return new Promise((resolve, reject) => {
        const url = `${baseURL}/direct_uploads`
        const upload = new DirectUpload(file, url, {
          directUploadWillCreateBlobWithXHR: xhr => {
            const authHeaders = $cookies.get('adminAuth')
            if (authHeaders) {
              xhr.setRequestHeader('access-token', authHeaders.accessToken)
              xhr.setRequestHeader('token-type', authHeaders.tokenType)
              xhr.setRequestHeader('client', authHeaders.client)
              xhr.setRequestHeader('expiry', authHeaders.expiry)
              xhr.setRequestHeader('uid', authHeaders.uid)
            }
          },
        })

        upload.create((error, blob) => {
          if (error) reject(error)
          else resolve(blob)
        })
      })
    }
  }
}