import { axios, baseURL } from '@/plugins/axios.js'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchCustomers(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/customers', { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCustomer(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/customers/${params.id}`, { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    createCustomer(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('api/v1/customers', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateCustomer(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .put(`api/v1/customers/${params.id}`, params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteCustomer(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/v1/customers/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    restoreCustomer(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`api/v1/customers/${id}/restore`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    printReport(ctx, params) {
      return new Promise((resolve, reject) => {
        const urlParams = new URLSearchParams()
        urlParams.append('sort_by', 'customer_number')
        urlParams.append('sort_dir', 'asc')
        urlParams.append('search_text', params.search_text || '')
        urlParams.append('filter_type', params.filter_type)

        fetch(`${baseURL}/api/v1/customers.pdf?${urlParams}`, {
          method: 'GET',
          headers: $cookies.get('adminAuth'),
        })
          .then(response => resolve(response.blob()))
          .catch(error => reject(error))
      })
    },
  }
}