import { axios, baseURL } from '@/plugins/axios.js'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchOrders(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/orders', { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchOrder(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/orders/${params.id}`, { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchReceipts(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/orders/${params.id}/receipts`, { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    createOrder(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('api/v1/orders', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateOrder(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .put(`api/v1/orders/${params.id}`, params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteOrder(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/v1/orders/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    restoreOrder(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`api/v1/orders/${id}/restore`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    printReport(ctx, params) {
      return new Promise((resolve, reject) => {
        const urlParams = new URLSearchParams()
        urlParams.append('sort_by', 'order_number')
        urlParams.append('sort_dir', 'asc')
        urlParams.append('search_text', params.search_text || '')
        urlParams.append('start_date', params.start_date)
        urlParams.append('end_date', params.end_date)
        urlParams.append('status', params.status)

        fetch(`${baseURL}/api/v1/orders.pdf?${urlParams}`, {
          method: 'GET',
          headers: $cookies.get('adminAuth'),
        })
          .then(response => resolve(response.blob()))
          .catch(error => reject(error))
      })
    },
  }
}