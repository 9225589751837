import { axios, baseURL } from '@/plugins/axios.js'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchItems(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/items', { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchItem(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/items/${params.id}`, { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    createItem(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('api/v1/items', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateItem(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .put(`api/v1/items/${params.id}`, params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteItem(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/v1/items/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    restoreItem(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`api/v1/items/${id}/restore`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchActivities(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`api/v1/items/${id}/activities`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    printReport(ctx, params) {
      return new Promise((resolve, reject) => {
        const urlParams = new URLSearchParams()
        urlParams.append('sort_by', 'item_number')
        urlParams.append('sort_dir', 'asc')
        urlParams.append('search_text', params.search_text || '')

        fetch(`${baseURL}/api/v1/items.pdf?${urlParams}`, {
          method: 'GET',
          headers: $cookies.get('adminAuth'),
        })
          .then(response => resolve(response.blob()))
          .catch(error => reject(error))
      })
    },
  }
}